:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --black: #000000;
  --white: #ffffff;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}

.cupon-card {
  align-items: center;
  width: 350px;
  height:fit-content;
  padding: 10px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.09);
  position:relative;
  margin: 2%;
  display: flex;

}
.cupon-cabecera p:nth-child(1){
  font-weight: bold;
  font-size:var(--lg);

}
.cupon-cabecera p:nth-child(2){
  font-size:var(--md);

}
.cupon-icons {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.09);
}
.cupon-borrar {
  right: -15px;
  top: -25px;
}
.cupon-detalles {
  display: flex;
  text-align: right;
}
.cupon-detalles p{
  font-size: var(--md);
  color: #3f60a0;
}
figure{  
  margin: 0;
  margin-left: 10px;
}
.cupon-flecha {
  width: 15px;
  height: 15px;
}
.cupon-logoEmpresa{
  width: 110px;
  padding: 15px;
  border-radius: 20%;
}
.itemBody{
  border-left: 2px dashed #bbb;
  padding-left: 20px
}
@media (max-width: 640px){
  .cupon-card {
    width: 300px;
  }
}
