.reporte-tabla{
  margin: 50px 50px 25px;
}
.reporte-cabecera{
  margin:  50px 50px 25px;
  text-align: center;
  display: flex;
  justify-content: center;

}
.cd{
  padding: 20px;
}
@media (max-width: 840px){
  .reporte-tabla{
    margin: 0;
  }
}
