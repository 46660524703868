:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --black: #000000;
  --white: #ffffff;
  --text-input-field: #f7f7f7;
  --hospital-green: #acd9b2;

  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
.fau-image-upload {
  text-align: center;

  width: 200px;
  margin-top: 40px;
}
.fau-image-upload > input {
  display: none;
}
.fau-formNumber {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  place-content: center;
}
.fau-btnAgregarComunicado {
  background: var(--hospital-green);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: black;
  align-self: right;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  align-items: bottom;
}
/*Cuando la pantalla sea para celular*/
@media (max-width: 640px) {
}
