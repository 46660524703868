.panel-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 80px;
  align-self: center;
  justify-self: center;
}
.panel-element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.panel-descrip{
  margin:50px;
  margin-left: 100px;
  font-size: 30px;
}
@media (max-width: 840px){
  .panel-grid-container {
    grid-template-columns: auto auto;
    margin: 30px;
  }
  .panel-descrip{
    margin:50px;
    margin-left: 100px;
    font-size: 24px;
    margin-left: 50px;

  }
}
