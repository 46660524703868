:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --black: #000000;
  --white: #ffffff;
  --text-input-field: #f7f7f7;
  --hospital-green: #acd9b2;

  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
.dc-titulo {
  font-size: 32px !important;
}
.dc-detalle {
  font-size: 24px !important;
  font-weight: normal !important;
  line-height: 50px;
}
.dc-carousel {
  margin: 200px;
  margin-top: 0px;
  margin-bottom: 30px;
}
.dc-descripcion {
  font-size: 24px !important;
  font-weight: normal !important;
  line-height: 50px;
}
.dc-documentos {
  margin-top: 20px;
}
.dc-inputdetalles {
  background: white;
  border: none;
  border-radius: 8px;
  font-size: var(--lg);
  outline: none;
}
.detallesComunicado {
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 50px;
}
.dc-grid-container {
  display: grid;
  grid-template-columns: auto;
  margin: 50px;
  align-self: center;
  justify-self: center;
}
.dc-element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.dc-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 12px;
}
.dc-input {
  background-color: var(--text-input-field);
  border: none;
  border-radius: 8px;
  height: 32px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
}
.dc-input-descripcion {
  height: 60px;
  resize: none;
  width: 100%;
}
.dc-input-detalles {
  height: 150px;
  resize: none;
  width: 100%;
}
.dc-input-titulo {
  width: 100%;
}

.dc-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dc-salida {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}
.dc-btnAgregarDocumento {
  background: var(--blue);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: white;
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.dc-agregarComunicado {
  margin-top: 50px;
}
.dc-btnAgregarComunicado {
  background: var(--hospital-green);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: black;
  align-self: right;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  align-items: bottom;
}
.dc-login-button {
  margin-bottom: 30px;
  margin-top: 24px;
}
/*Cuando la pantalla sea para celular*/
@media (max-width: 970px) {
  .dc-grid-container {
    grid-template-columns: auto;
    margin: 0;
  }
  .dc-descrip {
    margin: 50px;
    margin-left: 100px;
    font-size: 24px;
  }
  .dc-form {
	padding: 20px;
  }
  .dc-agregarComunicado {
    margin: 10px;
  }
  .dc-titulo {
    font-size: 24px !important;
  }
  .dc-detalle {
    font-size: 20px !important;
    font-weight: normal !important;
    line-height: 30px;
  }
  .dc-descripcion {
    font-size: 20px !important;
    font-weight: normal !important;
    line-height: 30px;
  }
  .dc-carousel {
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .detallesComunicado {
	margin-top: 10px;
	margin-left: 20px;
	margin-bottom: 20px;
  }
}
