:root {
  --blue : #3F60A0;
  --light-blue: #F0F4F8;
  --second-blue: #D1DBE1;
  --very-light-pink: #c7c7c7;
  --text-input-field: #f7f7f7;
  --hospital-green: #acd9b2;
  --black: #000000;
  --white: #ffffff;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
.imagen-item{
  width: 150px;
  height: 150px;
  fill: rebeccapurple !important;
}
.item-panel{
  display: inline-block;
  text-align: center;
  align-items: center;
  font-size: 24px
  
}
@media (max-width: 840px){
  .imagen-item{
    width: 100px;
    height: 100px;
  }
  .item-panel{
    display: inline-block;
    text-align: center;
    align-items: center;
    font-size: 12px
    
  }
}
