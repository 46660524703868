.cupones-grid-container {
  padding-top: 50px;
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 50px;
  align-self: center;
  justify-self: center;
}
.usuarios-buscador {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  margin-top: 70px;
}
.usuarios-element {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.usuarios-item{
  justify-content: center;
}
.cupones-element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.usarCupon{
  text-align: center;
}
.btnAgregarComunicado{
  width: 250px;
}
.cupones-flotante {
  display: scroll;
  position: fixed;
  bottom: 120px;
  right: 90px;
}
.cupones-boton {
  width: 60px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
@media (max-width: 740px) {
  .cupones-grid-container {
    grid-template-columns: auto auto;
    margin: 30px;
  }
}
@media (max-width: 640px) {
  .cupones-grid-container {
    grid-template-columns: auto;
    margin: 30px;
  }
  .cupones-flotante {
    bottom: 50px;
    right: 40px;
  }
  .usuarios-buscador{
    margin-top: 20px;
  }
}
