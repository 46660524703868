:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --green-status: #E1F4B2;
  --black: #000000;
  --white: #ffffff;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}

.user-card {
  margin:0px auto;

  width: fit-content;
  height:fit-content;
  padding: 10px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.09);
  position:relative;
  display: block;
text-align: center;
align-items: center;
}
.user-infocard{
  display: flex;
  align-items: center;
}
.user-icon{
  width: 50px;
  height: 50px;
  margin: 20px;
}
.user-informacion{
  justify-content: center;
  align-items: center;
  display: flex;
}
.user-nombre{
  margin: 0px;
}
.user-state{
  justify-content: center;
  align-items: center;
  display: flex;
}

.user-textState{
  margin: 0px;
  padding: 10px;
  border-radius: 30px;
}
.cupon-card{
  margin: 0px auto;
  margin-top: 20px;
}
@media (max-width: 640px){
  .card {
    width: 100%;
  }
  .user-icon{
    width: 30px;
    height: 30px;
    margin: 10px;
  }
  .user-info{
    width:100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
}
