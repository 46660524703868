:root {
  --blue:  #F3E62E !important;;  
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;

  --black: #000000;
  --white: #ffffff;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
body {
  font-family: "Quicksand", sans-serif;
  background: var(white);
  margin: 0;
}
.login {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}
.login-root {
  width: 100%;
  height: 100vh;
  display: flex;
}
.login-padre {
  flex-direction: row;
  display: flex;
  justify-content: center;
}
.login-padreIzquierda {
  height: 100vh;
  width: 50%;
  background: #050006;
  /*IMPORTANTE*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-padreDerecha {
  height: 100vh;
  width: 50%;
  /*IMPORTANTE*/

  display: flex;
  justify-content: center;
  align-items: center;
}
.login-hIzquierda {
  justify-content: center;
}
.login-hDerecha {
  display: grid;
  justify-content: center;
  width: 100%;
}
.login-logoLogin {
  width: 240px;
  margin-bottom: 48px;
  justify-self: center;
  display: block;
}

.login-title {
  font-size: var(--lg);
  justify-self: center;
}
.login-label {
  font-size: var(--sm);
  font-weight: bold;
  margin-bottom: 8px;
}
.login-input {
  background-color: var(--text-input-field);
  border: none;
  border-radius: 8px;
  height: 32px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
}
.login-input-email {
  margin-bottom: 24px;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.login-form a {
  color: var(--black);
  font-size: var(--sm);
  text-align: center;
  text-decoration: none;
  margin-bottom: 42px;
}
.login-primary-button {
  text-align: center;
  background: var(--blue);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: black;
  align-self: center;
  cursor: pointer;
  font-size: var(--md);
  font-weight: bold;
}

.login-login-button {
  margin-bottom: 30px;
  margin-top: 24px;
}
/*Cuando la pantalla sea para celular*/
@media (max-width: 840px) {
  .login {
    background: var(--black)
  }
  .login-logoLogin {
    width: 200px;
    margin-bottom: 100 px;
  }
  .login-title {
    display: none;
  }
  .login-root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 300px;
    height: fit-content;
  }
  .login-padreIzquierda {
    height: fit-content;
    width: 100%;
    background: none;
  }
  .login-padreDerecha {
    height: fit-content;
    width: 100%;
  }
  .login-form {
    width: 300px;
  }
  .login-secondary-button {
    width: 300px;
    position: absolute;
    bottom: 0;
    margin-bottom: 24px;
  }
  .login-label {
	font-size: var(--sm);
	font-weight: bold;
	margin-bottom: 8px;
	color: #d1dbe1;
  }
  .login-form a {
	color: white
  }
}
