.dct-detalles{
  margin: 50px;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.dct-itemCupon{
  justify-content: center;
  display: flex;
}
.dct-cardDetalles {
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.09);
  margin: 2%;
  width: 400px;
  padding: 30px;
  margin-top: 0px;
}
.inputdetalles {
  background: white;
  border: none;
  border-radius: 8px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
  width: 340px;
  outline: none;

}
.dct-icons{
  width: 26px;
  height: 26px;
}
.dct-buttons{
  display: flex;
  width: inherit;
  justify-content: center;
  text-align: center;
}
.dct-button{
  width: 50%;
}
.dct-contenedor{
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
.generarCupon{
  margin: 10%;
  margin-bottom: 2%;
}
@media (max-width: 640px){
  .dct-detalles{
    margin: 20px;
  }
  .dct-cardDetalles {
    margin-top: 30px;
  }
  .inputdetalles{
    width: 300px;
    outline: none;

  }
}
