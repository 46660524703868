:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --black: #000000;
  --white: #ffffff;
  --text-input-field: #f7f7f7;
  --hospital-green: #acd9b2;

  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  margin: 50px;
  align-self: center;
  justify-self: center;
}
.element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.input {
  background-color: var(--text-input-field);
  border: none;
  border-radius: 8px;
  height: 32px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
}
.input-descripcion{
  height: 60px;
  resize: none;

}
.input-detalles{
  height: 150px;
  resize: none;
  
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.agregarCupon{
  margin: 50px;
}
.btnAgregarImagen {
  background:var(--blue);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 170px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;

}
.grid-derecha{
  margin: 20px;
  margin-left: 50px;
}
.divAgregarImagen{
  margin-top: 50px;
 }

.btnAgregarComunicado {
  background:var(--hospital-green);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: black;
  align-self: right;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  align-items: bottom;
  margin-bottom: 16px;
}
.login-button {
  margin-bottom: 30px;
  margin-top: 24px;
}
.imagenEmpresa{
  height: 250px;
  align-content: center;
  align-items: center;
  text-align: center;
}
.divImagen{
  margin-top: 50px;
  text-align: center;

}
.agregarImagen{
  text-align: center;
  margin-top: 20px;
}
.btnAgrearImagenCupon{
  color: transparent;

}
.image-upload>input {
  display: none;
}
.formNumber{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  place-content: center;
  
}
/*Cuando la pantalla sea para celular*/
@media (max-width: 640px) {
  .grid-container {
    grid-template-columns: auto ;
    margin: 0;
  }
  .grid-item{
  }
  .grid-derecha{
    margin: 40px;
  }
  .descrip {
    margin: 50px;
    margin-left: 100px;
    font-size: 24px;
    margin-left: 50px;
  }
  .form{
    width: 300px;
  }
  .agregarCupon{
    margin: 0;
  }
  .agregarComunicado{
    margin: 10px;
  }
  .agregarImagen{
    margin: 10px;
  }
}
