:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --black: #000000;
  --white: #ffffff;
  --text-input-field: #f7f7f7;
  --hospital-green: #acd9b2;

  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
.agregarComunicado {
  margin-left: 340px;

  margin-right: 340px;

}
.fac-grid-container {
  display: grid;
  grid-template-columns: auto;
  margin: 50px;
  align-self: center;
  justify-self: center;
}
.fac-divImagen {
	display: flex;
  text-align: center;
  justify-content: center;
}
.fac-grid-item {
  margin: 30px;
}
.fac-element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.fac-image-upload {
  width: 100%;
}
.fac-image-upload > input {
  display: none;
}
.fac-imagenEmpresa {
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  text-align: center;
}
.fac-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.fac-input {
  background-color: var(--text-input-field);
  border: none;
  border-radius: 8px;
  height: 32px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
}
.fac-input-descripcion {
  height: 60px;
  resize: none;
}
.fac-input-detalles {
  height: 150px;
  resize: none;
}
.fac-form {
  display: flex;
  flex-direction: column;
}
.fac-salida {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}
.fac-btnAgregarDocumento {
  background: var(--blue);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: white;
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  color: transparent;
}
.fac-btnAgregarDocumento::-webkit-file-upload-button {
  visibility: hidden;
}
.fac-btnAgregarDocumento::before {
  content: "Agregar archivo";
  color: white;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  padding: 11px 8px;
}
.fac-agregarComunicado {
  margin-top: 50px;
}
.fac-btnAgregarComunicado {
  background: var(--hospital-green);
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  color: black;
  align-self: right;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  align-items: bottom;
}
.fac-login-button {
  margin-bottom: 30px;
  margin-top: 24px;
}
/*Cuando la pantalla sea para celular*/
@media (max-width: 640px) {
  .fac-grid-container {
    grid-template-columns: auto;
    margin: 0px;
  }
  .fac-descrip {
    margin-left: 100px;
    font-size: 24px;
  }
  .agregarComunicado {
    margin: 0px;
  }
  .fac-grid-item {
    margin: 10px;
  }
}
