:root {
  --red : #E31E24;
  --light-blue: #F0F4F8;
  --second-blue: #D1DBE1;
  --very-light-pink: #c7c7c7;
  --text-input-field: #f7f7f7;
  --hospital-green: #acd9b2;
  --black: #000000;
  --white: #ffffff;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
body {
  font-family: "Quicksand", sans-serif;
  background: var(--white);
  margin: 0;
}
h1{
  font-size:24px !important;
}
h2{
  font-size:20px !important;
}
h3{
  font-size:16px !important;
}
nav {
  background-color: var(--black);
  display: flex;
  padding: 20px;
  justify-content: space-between;
  padding-bottom: 20px;
}
.menu {
  display: none;
}
.logo-navbar {
  margin-bottom: 0px;
  width: 50px;
}
.hola {
  width: 50px;
  height: 50px;
  margin-right: 30px;
}
.salida {
  width: 40px;
  height: 40px;
  margin-right: 30px;
}
.navbar-left {
  display: flex;
  align-items: center;
}
.navbar-right {
  display: flex;
  align-items: center;
}
.navbar-center {
  display: flex;
  align-items: center;

}
.titulo{
  color: white;
  margin-right: 12px;
  margin: 0;
}
.name{
  color: var(--second-blue);
  margin: 0px;
  margin-left: 12px;
}
@media (max-width: 840px){
  .logo-navbar {
    width: 70px;
    display: none;
 }
  .titulo{
    color: var(--light-blue);
    font-size:small !important;
  }
  .navbar-center {
    display: flex;
    align-items: right;
  
  }
  .hola {
    width: 30px;
    height: 30px;
    margin-right: 10px;

  }
  .salida {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  .name{
    font-size:small !important;
  }


}