.carousel {
  margin: 100px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.tituloComunicado {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  color: #3f60a0;
  text-decoration: underline;
  margin-bottom: 0px;
}
.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 80px;
  align-self: center;
  justify-self: center;
}
.gridContainerCategoria {
  display: grid;
  grid-template-columns: auto auto auto;
  align-self: center;
  justify-self: center;
}
.element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

@media (max-width: 740px) {
  .carousel {
    height: auto; /* La altura se ajustará automáticamente según la relación de aspecto */
    margin: 0 auto; /* Centra horizontalmente el contenedor de la imagen */
  }
  .tituloComunicado {
    margin-top: 20px;
  }
  .gridContainer {
    grid-template-columns: auto auto;
    margin: 30px;
  }
  .element {
    margin-bottom: 30px;
  }
  .gridContainerCategoria {
    display: grid;
    grid-template-columns: auto;
    align-self: center;
    justify-self: center;
  }
 
}
