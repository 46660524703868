:root {
  --blue: #3f60a0;
  --light-blue: #f0f4f8;
  --second-blue: #d1dbe1;
  --black: #000000;
  --white: #ffffff;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
.imagen {
  width: 450px; /* Anchura deseada para el contenedor cuadrado */
  height: 150px; /* Altura deseada para el contenedor cuadrado */
  margin: 0 auto; /* Centra horizontalmente el contenedor */
  overflow: hidden; /* Recorta cualquier contenido que exceda las dimensiones */
  display: flex; /* Utiliza flexbox para centrar vertical y horizontalmente la imagen */
  justify-content: center; /* Centra horizontalmente la imagen */
  align-items: center;
  border-radius: 5px 0px 0px 5px;
}
.imagen img {
  width: 100%; /* Anchura deseada para el contenedor cuadrado */
  height: 100%; /* Altura deseada para el contenedor cuadrado */
  object-fit: cover;
}
.itemBody {
  padding: 20px;
}
.card {
  align-items: center;
  height: fit-content;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.09);
  position: relative;
  margin: 2%;
}
.cabecera p:nth-child(1) {
  font-weight: bold;
  font-size: var(--md);
}
.cabecera p:nth-child(2) {
  font-size: var(--sm);
}
.icons {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.09);
  right: -15px;
  top: -25px;
}
.borrar {
}
.detalles {
  display: flex;
  align-items: center;
  text-align: right;
}
.detalles p {
  font-size: var(--md);
  color: #3f60a0;
}
figure {
  margin: 0;
  margin-left: 10px;
}
.flecha {
  width: 15px;
  height: 15px;
}

@media (max-width: 940px) {
  .card {
    width: auto;
    border-radius: 10px;
  }
  .itemBody {
    padding-left: 20px;
  }
  .imagen {
    width: 350px; /* Anchura deseada para el contenedor cuadrado */
    height: 120px; /* Altura deseada para el contenedor cuadrado */
    border-radius: 10px 0px 0px 10px;
  }
  .imagen img {
    width: 100%; /* Anchura deseada para el contenedor cuadrado */
    height: 100%; /* Altura deseada para el contenedor cuadrado */
  }
}
