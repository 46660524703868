.cupones-grid-container {
  padding-top: 50px;
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 50px;
  align-self: center;
  justify-self: center;
}
.cupones-element {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.cupones-flotante {
  display: scroll;
  position: fixed;
  bottom: 120px;
  right: 90px;
}
.cupones-boton{
  width: 60px;
}
@media (max-width: 740px) {
  .cupones-grid-container {
    grid-template-columns: auto auto;
    margin: 30px;
  }
}
@media (max-width: 640px) {
  .cupones-grid-container {
    grid-template-columns: auto;
    margin: 30px;
  }
  .cupones-flotante {
    bottom: 50px;
    right: 40px;
  }
}
